import React from 'react';
import './style.css';

import Main from "./Main";

export default function App() {
  return (
    <div>
      

      <Main/>


    </div>
  );
}













