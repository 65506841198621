


import React from "react";

export default function Main(){

    return(

     <>

       

       <div className="Baap">
         

         <div className="input1">  

            <input type="text"/>




         </div>

       



         <div className="input1">  

       <input type="text"/>




       </div>






     <div className="input1">  

    <input type="text"/>




      </div>





       </div>



      






     </>






    )



}


























